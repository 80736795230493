import { getInstance } from '@/auth'
import OpenAPIClientAxios from 'openapi-client-axios'

export const api = new OpenAPIClientAxios({ definition: '/api/openapi.json' })

let axios_api

api.init()
    .then((client) => (axios_api = client))
    .then((client) => {
        client.interceptors.request.use(
            async function (req) {
                try {
                    const token = await getInstance().getTokenSilently()
                    if (token) {
                        req.headers.Authorization = `Bearer ${token}`
                        return req
                    } else {
                        getInstance().logout()
                    }
                } catch (error) {
                    if (error.error === 'login_required') {
                        return getInstance().logout()
                    }
                    throw error
                }
            },
            (error) => {
                return Promise.reject(error)
            }
        )
    })

export const APIInstance = () => axios_api
