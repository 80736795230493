export default {
    methods: {
        createToast(message, variant) {
            let title = variant == 'danger' ? 'Error' : 'Message'
            this.$bvToast.toast(message, {
                variant,
                solid: true,
                title: title,
                toaster: 'b-toaster-top-center',
                headerClass: ['p-2'],
                bodyClass: ['font-weight-bold py-3'],
            })
        },
    },
}
