<!--
    BuildProgress

    This component expects build summary as prop. It then 
    takes the responsibility of rendering the steps and log
    by polling at configured intervals if required.

    Once the build reached completed state, this emits an
    buildStatusChanged event.
  -->
<template>
    <div>
        <div v-if="stepsUnavailable" class="text-muted">Steps and Logs unavailable</div>
        <div v-else class="border-bottom">
            <BuildSteps
                :buildSteps="steps"
                :currentStepId.sync="latestStepId"
            />
            <BuildLog :buildId="buildId" :stepId="latestStepId" />
        </div>
    </div>
</template>

<script>
import { APIInstance } from '@/api'
import BuildSteps from '@/components/BuildSteps'
import BuildLog from '@/components/BuildLog'

export default {
    components: {
        BuildSteps,
        BuildLog,
    },
    name: 'BuildProgress',
    props: {
        buildId: null,
    },
    data: function () {
        return {
            buildDetails: null,
            detailPoller: null,
            latestStepId: null,
            latestBuildStatus: null,
        }
    },
    methods: {
        fetchBuildDetails() {
            if (!this.buildId) return

            APIInstance()
                .buildDetails(this.buildId)
                .then((d) => {
                    this.buildDetails = d.data
                    if(this.latestBuildStatus != this.buildDetails.result){
                        this.notifyBuildStatusChanged()
                        this.latestBuildStatus = this.buildDetails.result;
                    }
                    if (this.buildDetails.complete) {
                        clearInterval(this.detailPoller)
                    }
                    
                })
        },
        beginPolling() {
            this.fetchBuildDetails()
            if (this.detailPoller) clearInterval(this.detailPoller)
            this.detailPoller = setInterval(this.fetchBuildDetails, 2000)
        },
        notifyBuildStatusChanged() {
            this.$emit('buildStatusChanged')
        },
    },
    watch: {
        buildId() {
            this.beginPolling()
        },
    },
    computed: {
        steps() {
            if (this.buildDetails && this.buildDetails.steps)
                return this.buildDetails.steps
            return []
        },
        stepsUnavailable() {
            // Legacy builds, does not have step information. Display a message.
            return (
                this.buildDetails &&
                this.buildDetails.complete &&
                !this.buildDetails.steps.length
            )
        },
    },
    mounted: function () {
        this.beginPolling()
    },
    beforeDestroy: function () {
        clearInterval(this.detailPoller)
    },
}
</script>
