export default {
    data: function () {
        return {
            loading: true,
        }
    },
    methods: {
        showLoading() {
            this.loading = true
        },
        hideLoading() {
            this.loading = false
        },
    },
}
