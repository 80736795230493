<template>
    <div class="project-cell align-items-start">
        <build-status-icon
            v-bind:build="build"
            v-bind:detail="false"
        ></build-status-icon>
        <div class="d-flex flex-column ml-3 w-100">
            <strong>{{ projectName + ' ' + build.id.slice(-7) }}</strong>
            <span class="d-block timestamp">
                Queued at: {{ formatDateTime(build.timestamp) }}
            </span>
        </div>
    </div>
</template>

<script>
import BuildStatusIcon from '../components/BuildStatusIcon'
import { formatDateTime } from '../../util/util'

export default {
    name: 'BuildCell',
    components: {
        BuildStatusIcon,
    },
    props: ['projectName', 'build'],
    methods: {
        formatDateTime: formatDateTime,
    },
}
</script>

<style lang="scss" scoped>
.timestamp {
    font-size: 11px;
    color: gray;
}
</style>
