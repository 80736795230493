<template>
    <div id="main">
        <NavBar>
            <template v-slot:nav-left>
                <slot name="nav-left">
                    <div class="ml-2">
                        <p id="title">Moku Cloud Compile</p>
                        <p id="sub-title">
                            by Liquid Instruments<span>BETA</span>
                        </p>
                    </div>
                </slot>
            </template>
            <template v-slot:nav-right>
                <slot name="nav-right"></slot>
            </template>
        </NavBar>
        <SideBar />
        <div id="content">
            <b-alert show variant="warning" class="rounded-0 mb-0">
                <b-icon icon="bell-fill" class="mr-2"></b-icon>
                <span class="font-weight-bolder">
                    <u>
                        {{maintenanceMessage}}
                    </u>
                </span>
            </b-alert>
            <slot />
        </div>
    </div>
</template>

<script>
import NavBar from '../components/NavBar'
import SideBar from '../components/SideBar'

export default {
    components: {
        NavBar,
        SideBar,
    },
    data() {
        return {
        maintenanceStartTime: new Date(Date.UTC(2024, 9, 28, 7, 0, 0)),
        maintenanceEndTime: new Date(Date.UTC(2024, 9, 28, 1600, 0, 0))
        };
    },
    computed: {
        maintenanceMessage() {
            const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const startTime = this.maintenanceStartTime.toLocaleString(undefined, {
                hour: 'numeric',
                minute: 'numeric',
                timeZone: browserTimeZone
            });
            const endTime = this.maintenanceEndTime.toLocaleString(undefined, {
                hour: 'numeric',
                minute: 'numeric',
                timeZone: browserTimeZone
            });
            const date = this.maintenanceStartTime.toLocaleDateString(undefined, {
                weekday: 'long',
                month: 'long',
                day: 'numeric',
                timeZone: browserTimeZone
            });
            return `Attention! Planned Maintenance: Moku Cloud Compile will not be available on ${date} between ${startTime} and ${endTime}. Please note that there may be additional disruptions beyond the specified timeframe`;
        }
  }
}
</script>

<style lang="scss" scoped>
$left_gutter: 5rem;
$top_gutter: 52px;

#sidebar {
    box-sizing: border-box;
    padding-top: $top_gutter;
    height: 100%;
    width: 5rem;
}

#main {
    height: 100vh;
    overflow-y: hidden;
}

#content {
    box-sizing: border-box;
    padding-top: $top_gutter;
    padding-left: $left_gutter;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

#title,
#sub-title {
    color: white;
    padding: 0;
    margin: 0;

    span {
        float: right;
    }
}

#title {
    font-size: 1.3em;
}

#sub-title {
    display: block;
    line-height: 0.4em;
    font-size: 0.6em;
}
</style>
