<template>
    <b-col
        cols="auto"
        class="border-right d-flex flex-column h-100"
        style="min-width: 18rem; max-width: 18rem"
    >
        <div
            ref="fileTreeHeader"
            class="
                d-flex
                justify-content-between
                align-items-center
                border-bottom
                px-3
            "
            style="height: 2.5rem"
        >
            <span class="text-secondary">Files</span>
            <div class="ml-auto">
                <b-button
                    variant="transparent"
                    class="text-secondary p-0"
                    v-b-modal.new-file-modal
                >
                    <ph-file-plus size="22" class="mr-1" />
                </b-button>
                <!-- CC-259: Enable this along with IP core support -->
                <!-- <b-button
                    variant="transparent"
                    class="text-secondary p-0"
                    title="Import IP Core"
                    v-b-modal.file-upload-modal
                >
                    <ph-upload-simple :size="22" />
                </b-button> -->
            </div>
        </div>
        <div class="file-tree flex-grow-1">
            <ul class="file-tree__content">
                <li
                    v-for="file in projectFiles"
                    :key="file.id"
                    :class="{ active: activeFile && activeFile.id === file.id }"
                    @click="selectFile(file)"
                >
                    <div class="file-actions">
                        <span
                            class="text-truncate"
                            v-b-tooltip.hover.bottom="file.name"
                        >
                            {{ file.name }}
                            <span
                                v-if="dirtyFiles.includes(file.id)"
                                class="edited-dot"
                            >
                                •
                            </span>
                        </span>
                        <div class="d-flex align-items-center">
                            <b-button
                                variant="transparent"
                                size="sm"
                                @click.stop="renameFileModal(file)"
                            >
                                <ph-pencil size="16" />
                            </b-button>
                            <b-button
                                class="ml-1"
                                variant="transparent"
                                size="sm"
                                @click.stop="deleteFile(file)"
                            >
                                <ph-x-circle size="16" />
                            </b-button>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="file-tree__details">
            <Accordion
                title="Build Configuration"
                headerClass="border-top"
                :showOnStart="true"
            >
                <ProjectConfiguration
                    ref="refProjectConfiguration"
                    v-if="projectDetails !== null"
                    v-bind:projectDetails="projectDetails"
                ></ProjectConfiguration>
            </Accordion>
        </div>
        <div class="file-tree__details">
            <Accordion
                title="Latest Build"
                headerClass="border-top"
                :showOnStart="true"
            >
                <div v-if="latestBuild && latestBuild.id">
                    <div class="d-flex align-items-start">
                        <build-cell
                            v-bind:projectName="''"
                            v-bind:build="latestBuild"
                        ></build-cell>
                    </div>
                    <!-- <div
                        v-if="!latestBuild.complete"
                        class="d-flex align-items-start"
                    >
                        <b-button
                            class="text-white w-100 py-1 my-1"
                            size="md"
                            variant="danger"
                            @click="cancelBuild(latestBuild.id)"
                            >Cancel</b-button
                        >
                    </div> -->

                    <ul class="resources">
                        <li
                            class="text-truncate"
                            v-for="artifact in latestBuild.artifacts"
                            v-b-tooltip.hover.righttop="artifact.name"
                            :key="artifact.id"
                            @click="
                                downloadFile(
                                    '/api/builds/' +
                                        latestBuild.id +
                                        '/artifacts/' +
                                        artifact.id,
                                    artifact.name
                                )
                            "
                        >
                            <b-link
                                ><ph-download-simple />
                                <span class="ml-2">
                                    {{ artifact.name }}
                                </span>
                            </b-link>
                        </li>
                    </ul>
                </div>
                <div v-else class="row">
                    <span class="mx-auto text-muted">No build history</span>
                </div>
            </Accordion>
        </div>

        <b-modal
            centered
            id="new-file-modal"
            title="New File"
            @show="resetFileForm"
            @ok="createFile"
        >
            <b-form @submit="createFile">
                <b-form-group
                    label="File Name"
                    label-for="file-name"
                    :invalid-feedback="formFile.feedback"
                    :state="formFile.nameState"
                >
                    <b-form-input
                        v-model="formFile.name"
                        id="file-name"
                        :state="formFile.nameState"
                        required
                        :autofocus="true"
                    />
                </b-form-group>
            </b-form>
            <template #modal-ok>Create</template>
        </b-modal>

        <b-modal
            centered
            id="rename-file-modal"
            title="Rename File"
            @ok="renameFile"
        >
            <b-form @submit="renameFile">
                <b-form-group
                    label="File Name"
                    label-for="file-rename"
                    :invalid-feedback="formFile.feedback"
                    :state="formFile.nameState"
                >
                    <b-form-input
                        v-model="formFile.name"
                        id="file-rename"
                        :state="formFile.nameState"
                        required
                        :autofocus="true"
                    />
                </b-form-group>
            </b-form>
            <template #modal-ok>Rename</template>
        </b-modal>
        <file-uploader id="file-upload-modal" />
    </b-col>
</template>

<script>
import {
    PhFilePlus,
    PhDownloadSimple,
    PhPencil,
    PhXCircle,
    // PhUploadSimple,
} from 'phosphor-vue'
import Accordion from '../Accordion.vue'
import {
    clampToRange,
    describeArc,
    composeBitstreamName,
} from '../../../util/util'
import downloadFile from '../../mixins/download'
import BuildCell from '../BuildCell'
import ProjectConfiguration from '../ProjectConfiguration.vue'
import FileUploader from './FileUploader.vue'

export default {
    name: 'FileExplorer',
    components: {
        PhXCircle,
        PhPencil,
        PhDownloadSimple,
        PhFilePlus,
        // PhUploadSimple,
        Accordion,
        BuildCell,
        ProjectConfiguration,
        FileUploader,
    },
    mixins: [downloadFile],
    data() {
        return {
            /** The old name of the file */ 
            formFileName: '',
            formFile: { name: '' },
        }
    },
    computed: {
        projectFiles() {
            return Object.values(this.$store.state.projectFiles)
        },
        projectDetails() {
            return this.$store.state.openProject
        },
        latestBuild() {
            return this.$store.state.latestBuild
        },
        activeFile() {
            return this.$store.state.activeFile
        },
        dirtyFiles() {
            return this.$store.state.dirtyFiles
        },
    },
    methods: {
        composeBitstreamName: composeBitstreamName,
        selectFile(file) {
            this.$store.dispatch('setActiveFile', file)
        },
        ellipseAttrsToPath(value) {
            const r = 15
            const c = clampToRange(value, {
                oldMin: 0,
                oldMax: 100,
                newMin: 0,
                newMax: 360,
            })
            return describeArc(r, r, r, 0, c)
        },
        resetFileForm() {
            this.formFile = {
                name: '',
                nameState: null,
                feedback: null,
            }
        },
        validateFileName() {
            var re = /^[\w,\s-]+\.[A-Za-z]+$/
            return this.formFile.name.length > 0 && re.test(this.formFile.name)
        },
        createFile(bvModalEvt) {
            const name = this.formFile.name
            const project = this.$store.state.openProject
            bvModalEvt.preventDefault()
            if (!this.validateFileName()) {
                this.formFile.nameState = false
                this.formFile.feedback = 'Please enter a valid file name'
                return
            } else {
                this.formFile.nameState = true
            }
            this.$store
                .dispatch('newFile', { name, project })
                .then((r) => {
                    this.$store
                        .dispatch('setActiveFile', r)
                        .then(() => this.$bvModal.hide('new-file-modal'))
                })
                .catch((e) => {
                    this.formFile.nameState = false
                    this.formFile.feedback = e.response.data.detail
                    return
                })
        },
        renameFileModal(file) {
            this.formFile = Object.assign({ feedback: '', nameState: true }, file)
            this.formFileName = file.name
            this.$bvModal.show('rename-file-modal')
        },
        renameFile(modalEvent) {
            // Don't close the modal
            modalEvent.preventDefault()

            let project = this.projectDetails.id
            let file = this.formFile
            let name = this.formFileName

            this.formFile.nameState = this.validateFileName()
            this.formFile.feedback = 'Please enter a valid file name'
            if (!this.formFile.nameState) return

            this.$store
              .dispatch('renameFile', { project, file, name })
              .then(() => {
                  this.$bvModal.hide('rename-file-modal') 
              })
              .catch((e) => {
                this.formFile.nameState = false
                this.formFile.feedback = e.response.data.detail
                return
              })
        },
        deleteFile(file) {
            let project_id = this.projectDetails.id
            this.$bvModal
                .msgBoxConfirm('Delete ' + file.name, {
                    title: 'Delete File',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'DELETE',
                    cancelTitle: 'Cancel',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value)
                        this.$store.dispatch('deleteFile', { project_id, file })
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        cancelBuild(buildId) {
            this.$store.dispatch('cancelBuild', buildId)
        },
        validateBuildProperties() {
            return this.$refs.refProjectConfiguration.arePropertiesValid()
        },
    },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.file-tree {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    min-height: 50px;
}

.file-tree__details {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.file-tree__content {
    list-style: none;
    display: flex;
    padding: 0;
    flex-direction: column;

    > li {
        cursor: pointer;
        padding: 0.3rem 1rem;

        button {
            display: none;
            color: var(--secondary);
            padding: 0;
            transition: none;
        }

        &:hover {
            background-color: $gray-200;

            button {
                display: flex;
            }
        }

        &.active {
            background-color: var(--primary);
            color: var(--white);

            .edited-dot {
                color: var(--white);
            }

            button {
                display: flex;
                color: var(--white);
            }
        }
    }
}

.file-tree__content {
    li:not(.active) .file-actions button:hover {
        background-color: $gray-300;
    }
}

.file-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:last-child {
        z-index: 9999;
    }
}

.resources {
    list-style: none;
    padding: 0;
    margin-top: 1rem;
    overflow: hidden;
    margin-bottom: 0;

    > li {
        cursor: pointer;
        padding: 0.5rem 1rem;
        background-color: $gray-100;
    }

    > li:not(:last-child) {
        border-bottom: 1px solid $gray-300;
    }
}
</style>
