<template>
    <b-row
        ref="editorNav"
        no-gutters
        class="editor-nav bg-primary border-bottom"
    >
        <b-col cols="7">
            <div class="d-flex align-items-center h-100">
                <div
                    class="
                        border-right
                        h-100
                        d-flex
                        align-items-center
                        justify-content-center
                    "
                    style="width: 5rem"
                    @click="rootClick()"
                >
                    <img
                        class="cc-icon"
                        src="../assets/cc-icon.png"
                        style="height: 32px"
                    />
            </div>

                <slot name="nav-left"></slot>
            </div>
        </b-col>

        <b-col cols="5">
            <div class="d-flex justify-content-end align-items-center p-1">
                <slot name="nav-right"></slot>
                <b-dropdown class="nav-user" variant="primary">
                    <template #button-content>
                        <b-avatar :src="userAvatar" size="30px"></b-avatar>
                    </template>
                    <b-dropdown-item to="/preferences"
                        >Preferences</b-dropdown-item
                    >
                    <b-dropdown-item @click="signOut">Sign Out</b-dropdown-item>
                </b-dropdown>
            </div>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: 'NavBar',
    data() {
        return {
            editingProjectName: false,
        }
    },
    computed: {
        userName() {
            return (
                this.$auth.user.name ||
                this.$auth.user.nickname ||
                this.$auth.user.email ||
                'Anonymous'
            )
        },
        userAvatar() {
            return (
                this.$auth.user.picture || 'https://via.placeholder.com/30x30'
            )
        },
    },
    methods: {
        signOut() {
            this.$auth.logout({
                returnTo: window.location.origin,
            })
        },
        rootClick(){
            this.$router.push({'path': '/projects'})
        }
    },
}
</script>

<style lang="scss" scoped>
.readonly-input {
    background-color: transparent;
    border-color: transparent;
}

.editor-nav {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
}

.editor-nav {
    z-index: 10;
}

.cc-icon {
    filter: invert(100%);
}
</style>
