<template>
    <div class="build-status-icon" style="margin-top: 3px">
        <div class="d-flex" v-if="build.result == 'CANCELLED'">
            <ph-warning-circle :size="24" color="#ffbb00" weight="fill" />
            <p class="font-weight-lighter m-auto pl-2" v-if="detail">
                CANCELLED
            </p>
        </div>
        <div class="d-flex" v-else-if="!build.result">
            <ph-hourglass-medium
                weight="fill"
                variant="secondary"
                font-scale="2"
                tooltip="Waiting in queue"
            ></ph-hourglass-medium>
            <p
                class="font-weight-lighter text-secondary m-auto pl-2"
                v-if="detail"
            >
                IN QUEUE
            </p>
        </div>
        <div class="d-flex" v-else-if="build.result == 'INPROGRESS'">
            <ph-circle :size="24" color="#ffbb00" weight="fill">
                <animate
                    attributeName="opacity"
                    values="0;1;0"
                    dur="1s"
                    repeatCount="indefinite"
                />
            </ph-circle>
            <p
                class="font-weight-lighter text-warning m-auto pl-2"
                v-if="detail"
            >
                IN PROGRESS
            </p>
        </div>
        <div
            class="d-flex"
            v-else-if="build.complete && build.result == 'SUCCESS'"
        >
            <ph-check-circle :size="24" color="#1fb800" weight="fill" />
            <p
                class="font-weight-lighter text-success m-auto pl-2"
                v-if="detail"
            >
                SUCCESS
            </p>
        </div>
        <div class="d-flex" v-else>
            <ph-x-circle :size="24" color="#e00000" weight="fill" />
            <p
                class="font-weight-lighter text-danger m-auto pl-2"
                v-if="detail"
            >
                FAILURE
            </p>
        </div>
    </div>
</template>

<script>
import {
    PhCircle,
    PhXCircle,
    PhCheckCircle,
    PhWarningCircle,
    PhHourglassMedium,
} from 'phosphor-vue'
export default {
    name: 'BuildStatusIcon',
    components: {
        PhCircle,
        PhXCircle,
        PhCheckCircle,
        PhWarningCircle,
        PhHourglassMedium,
    },
    props: {
        build: {
            type: Object,
            default: null,
        },
        detail: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss" scoped>
.build-status-icon svg {
    height: 2rem;
    width: 2rem;
}
</style>
