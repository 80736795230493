import { ViewPlugin } from '@codemirror/view'
import { Decoration } from '@codemirror/view'
import { RangeSetBuilder } from '@codemirror/rangeset'

const stripeWarning = Decoration.line({
    attributes: { class: 'text-warning' },
})

const stripeInfo = Decoration.line({
    attributes: { class: 'text-info' },
})

const stripeError = Decoration.line({
    attributes: { class: 'text-danger bg-light' },
})

const stripeMuted = Decoration.line({
    attributes: { class: 'text-muted' },
})

const markLine = Decoration.mark({
    attributes: { class: 'd-none' },
})

function stripeDeco(view) {
    let builder = new RangeSetBuilder()
    for (let { from, to } of view.visibleRanges) {
        for (let pos = from; pos <= to; ) {
            let line = view.state.doc.lineAt(pos)
            if (line.text.startsWith('#'))
                builder.add(line.from, line.from, stripeMuted)
            else if (line.text.startsWith('ERROR'))
                builder.add(line.from, line.from, stripeError)
            else if (line.text.startsWith('INFO'))
                builder.add(line.from, line.from, stripeInfo)
            else if (line.text.startsWith('WARNING'))
                builder.add(line.from, line.from, stripeWarning)
            else if (line.text.startsWith('CRITICAL WARNING'))
                builder.add(line.from, line.from, stripeWarning)

            builder.add(pos, pos, markLine)
            pos = line.to + 1
        }
    }
    return builder.finish()
}

const showStripes = ViewPlugin.fromClass(
    class {
        decorations

        constructor(view) {
            this.decorations = stripeDeco(view)
        }

        update(update) {
            if (update.docChanged || update.viewportChanged) {
                this.decorations = stripeDeco(update.view)
            }
        }
    },
    {
        decorations: (v) => v.decorations,
    }
)

export function displayStripes() {
    return [showStripes]
}
