<template>
    <div id="main">
        <b-row class="h-100" no-gutters>
            <b-col xl="6">
                <b-container class="split-layout">
                    <h1 class="font-weight-bold text-center mb-4">
                        Create Account
                    </h1>
                    <p class="text-muted text-center mb-0">
                        Fill in your details below to get started
                    </p>

                    <b-alert show class="my-5">
                        <div class="d-flex align-items-center">
                            <b-icon-exclamation-triangle
                                style="width: 24px; height: 24px"
                            />
                            <span class="ml-3">
                                Sorry you've entered the incorrect password.
                            </span>
                        </div>
                    </b-alert>

                    <b-form class="w-100">
                        <b-form-group label="Name" label-for="name">
                            <b-form-input
                                id="name"
                                type="text"
                                name="name"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group label="Email" label-for="email">
                            <b-form-input
                                id="email"
                                type="email"
                                name="email"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group label="Password" label-for="password">
                            <b-form-input
                                id="password"
                                type="password"
                                name="password"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group>
                            <b-form-checkbox
                                id="accept_terms"
                                name="accept_terms"
                            >
                                <span class="text-muted">
                                    I accept to the Terms &amp; Conditions
                                </span>
                            </b-form-checkbox>
                        </b-form-group>
                        <b-button type="submit" block variant="primary">
                            Register
                        </b-button>
                    </b-form>
                    <p class="text-center mt-5">
                        Have an account? <a href="#">Sign in</a>
                    </p>
                </b-container>
            </b-col>
            <b-col xl="6" class="bg-primary right-side">
                <b-container class="split-layout">
                    <img src="../assets/cc-logo.png" />
                    <span class="d-block text-white text-center mt-8 lead">
                        Code, Compile and Deploy custom instruments to your Moku
                    </span>
                </b-container>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { BIconExclamationTriangle } from 'bootstrap-vue'

export default {
    name: 'SignUp',
    components: {
        BIconExclamationTriangle,
    },
}
</script>

<style lang="scss" scoped>
.right-side img {
    height: auto;
    width: 100%;
    max-height: 136px;
    max-width: 525px;
    margin: 0 auto;
}
</style>
