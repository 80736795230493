<template>
    <div class="bg-white flex-nowrap">
        <ul class="file-tabs nav nav-tabs simple-tabs">
            <li
                class="nav-item"
                v-for="file in openFiles"
                :key="file.id"
                @click="selectFile(file)"
            >
                <a
                    class="nav-link rounded-0 text-left border-right"
                    :class="[
                        activeFile && activeFile.id === file.id
                            ? 'active bg-white'
                            : 'bg-gray-200',
                        { 'tab-edited': dirtyFiles.includes(file.id) },
                    ]"
                    href="javascript:void(0)"
                >
                    <div
                        class="
                            d-flex
                            justify-content-between
                            align-items-center
                        "
                    >
                        <div class="pr-2 overflow-hidden">
                            <span> {{ file.name }} </span>
                        </div>
                        <b-button
                            variant="transparent"
                            class="p-0 tab-close"
                            @click.stop="$emit('close', file)"
                        >
                            <ph-x size="10" weight="bold"
                                v-if="!dirtyFiles.includes(file.id)"
                                style="fill(--secondary)"
                            />
                            <span v-else class="edited-dot"> • </span>
                        </b-button>
                    </div>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
import { PhX } from 'phosphor-vue'
export default {
    name: 'FileTabs',
    components: {
        PhX,
    },
    computed: {
        activeFile() {
            return this.$store.state.activeFile
        },
        openFiles() {
            return this.$store.state.openFiles
        },
        dirtyFiles() {
            return this.$store.state.dirtyFiles
        },
    },
    methods: {
        selectFile(file) {
            this.$store.dispatch('setActiveFile', file)
        },
    },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.file-tabs {
    .nav-link:not(.active) {
        &:not(.tab-edited) {
            .tab-close {
                visibility: hidden;
            }
        }

        .tab-close {
            &:hover {
                background-color: $gray-300;
            }
        }
    }

    .nav-link.active .tab-close:hover {
        background-color: $gray-200;
    }

    .nav-link:hover .tab-close {
        visibility: visible;
    }
}

.tab-close {
    height: 1.5rem;
    width: 1.5rem;
    padding: 0 !important;
}

.edited-dot {
    display: block;
    margin-top: -2px;
}
</style>
