<template>
    <Main>
        <b-overlay :show="loading">
            <section class="mt-3">
                <b-container>
                    <b-row class="h-100">
                        <b-col class="d-flex align-items-center">
                            <h2 class="font-weight-bold mb-0">Projects</h2>
                        </b-col>
                        <b-col class="mt-3 mt-lg-0 d-flex" cols="3">
                            <b-button
                                class="ml-3"
                                block
                                variant="primary"
                                v-b-modal.new-project-modal
                            >
                                New Project
                            </b-button>
                        </b-col>
                    </b-row>
                    <b-table
                        id="projects-table"
                        class="mt-6"
                        :fields="columns"
                        :items="projects"
                        :per-page="perPage"
                        :current-page="currentPage"
                        responsive
                    >
                        <template #cell(project)="data">
                            <div class="project-cell">
                                <div class="project-icon">
                                    <ph-code
                                        :size="20"
                                        color="white"
                                        weight="fill"
                                    />
                                </div>
                                <b-link
                                    :to="'/e/' + data.value.id"
                                    class="ml-3"
                                    >{{ data.value.name }}</b-link
                                >
                            </div>
                        </template>

                        <template #cell(latest_build)="data">
                            <div class="d-flex flex-column">
                                <div v-if="!data.value">
                                    <b-spinner></b-spinner>
                                </div>
                                <div v-else-if="data.value.id">
                                    <div class="d-flex align-items-start">
                                        <build-cell
                                            v-bind:projectName="''"
                                            v-bind:build="data.value"
                                        ></build-cell>
                                    </div>
                                </div>
                                <div v-else>
                                    <span class="text-muted"
                                        >No build history</span
                                    >
                                </div>
                            </div>
                        </template>

                        <template #cell(last_updated)="data">
                            <span>
                                {{ formatDate(data.value) }}
                            </span>
                            <span class="gray-500 ml-2">•</span>
                            <span class="ml-2">
                                {{ formatToRelativeTime(data.value) }}
                            </span>
                        </template>

                        <template #cell(actions)="data">
                            <b-dropdown
                                variant="transparent"
                                toggle-class="text-left p-0"
                                right
                                no-caret
                            >
                                <template #button-content>
                                    <ph-dots-three-outline-vertical
                                        weight="fill"
                                    />
                                </template>
                                <b-dropdown-item
                                    @click="renameProjectModal(data.value)"
                                    >Rename</b-dropdown-item
                                >
                                <b-dropdown-item
                                    @click="deleteProject(data.value)"
                                    >Delete</b-dropdown-item
                                >
                            </b-dropdown>
                        </template>
                    </b-table>

                    <div v-if="projects.length === 0" class="empty-table">
                        <div class="empty-table__icon">
                            <ph-code :size="32" weight="fill" />
                        </div>
                        <p class="empty-table__content mt-4 mb-0 text-muted">
                            Hi there, it seems like you don’t<br />have any
                            projects a the moment.<br />Would you like to
                            <a
                                href="javascript:void(0)"
                                v-b-modal.new-project-modal
                                >create</a
                            >
                            one?
                        </p>
                    </div>

                    <b-pagination
                        v-if="projects.length > perPage"
                        class="justify-content-center my-6"
                        v-model="currentPage"
                        :total-rows="projects.length"
                        :per-page="perPage"
                        aria-controls="projects-table"
                    ></b-pagination>
                </b-container>
            </section>
        </b-overlay>

        <b-modal
            centered
            id="new-project-modal"
            title="New Project"
            @show="resetFormProject"
            @ok="createProject"
        >
            <b-form @submit="createProject">
                <b-form-group
                    label="Project Name"
                    label-for="project"
                    :description="NAME_DESCRIPTION"
                    :invalid-feedback="formProject.feedback"
                    :state="formProject.nameState"
                >
                    <b-form-input
                        v-model="formProject.name"
                        :state="formProject.nameState"
                        :autofocus="true"
                        id="project"
                        required
                    />
                </b-form-group>
                <b-form-group label="Project Template">
                    <b-form-select
                        v-model="formProject.template"
                        :options="templates"
                    >
                        <template #first>
                            <b-form-select-option :value="null">
                                (Optional) Choose from one of the available
                                templates
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
            </b-form>
            <template #modal-ok>Create</template>
        </b-modal>

        <b-modal
            centered
            id="rename-project-modal"
            title="Rename Project"
            @ok="renameProject"
        >
            <b-form @submit="renameProject">
                <b-form-group
                    label="Project Name"
                    label-for="project"
                    :description="NAME_DESCRIPTION"
                    :invalid-feedback="formProject.feedback"
                    :state="formProject.nameState"
                >
                    <b-form-input
                        v-model="formProject.name"
                        :autofocus="true"
                        :state="formProject.nameState"
                        id="project"
                        required
                    />
                </b-form-group>
            </b-form>
            <template #modal-ok>Rename</template>
        </b-modal>
    </Main>
</template>

<script>
import Vue from 'vue'
import { APIInstance } from '@/api'
import { PhCode, PhDotsThreeOutlineVertical } from 'phosphor-vue'
import Main from '../layouts/Main'
import { clampToRange, describeArc } from '../../util/util'
import createToast from '../mixins/toast'
import loading from '../mixins/loading'
import {
    formatDate,
    formatToRelativeTime,
    createProjectFromTemplate,
} from '../../util/util'
import BuildCell from '../components/BuildCell'

export default {
    name: 'Projects',
    components: {
        Main,
        PhCode,
        PhDotsThreeOutlineVertical,
        BuildCell,
    },
    mixins: [createToast, loading],
    mounted() {
        this.showLoading()
        this.$store
            .dispatch('fetchProjects')
            .then(() => {
                this.hideLoading()
            })
            .catch((e) => this.createToast(e.response.data.detail, 'danger'))
        this.$store.dispatch('projectTemplates')
    },
    data() {
        return {
            columns: [
                { key: 'project', sortable: true },
                { key: 'latest_build' },
                { key: 'last_updated', sortable: true },
                { key: 'actions', label: '' },
            ],
            formProject: {
                name: '',
                nameState: null,
                feedback: null,
                template: null,
            },
            currentPage: 1,
            perPage: 10,
        }
    },
    computed: {
        NAME_DESCRIPTION() {
            return 'A unique project name made up of letters (a-z, A-Z), numbers (0-9), underscores (_), and spaces'
        },
        NAME_FEEDBACK() {
            return 'Project names must be made up of letters (a-z, A-Z), numbers (0-9), underscores (_), and spaces'
        },
        projects() {
            return Object.values(this.$store.state.local_projects).map(
                (project) => {
                    return {
                        project: project,
                        latest_build: null,
                        last_updated: project.timestamp,
                        actions: project,
                    }
                }
            )
        },
        templates() {
            return Object.values(this.$store.state.projectTemplates).map(
                (template) => {
                    return {
                        text: template.title,
                        value: template.path,
                    }
                }
            )
        },
    },
    watch: {
        projects() {
            this.fetchBuilds()
        },
    },
    methods: {
        formatDate: formatDate,
        formatToRelativeTime: formatToRelativeTime,
        createProjectFromTemplate: createProjectFromTemplate,
        ellipseAttrsToPath: (value) => {
            const r = 15
            const c = clampToRange(value, {
                oldMin: 0,
                oldMax: 100,
                newMin: 0,
                newMax: 360,
            })
            return describeArc(r, r, r, 0, c)
        },
        resetFormProject() {
            this.formProject.name = ''
            this.formProject.nameState = null
            this.formProject.feedback = null
            this.formProject.template = null
        },
        validateProjectName() {
            var re = /^[\w+ ]+$/
            return (
                this.formProject.name.length > 0 &&
                re.test(this.formProject.name)
            )
        },
        createProject(bvModalEvt) {
            bvModalEvt.preventDefault()
            if (!this.validateProjectName()) {
                this.formProject.nameState = false
                this.formProject.feedback = this.NAME_FEEDBACK
                return
            } else {
                this.formProject.nameState = true
            }

            this.showLoading()
            if (this.formProject.template) {
                createProjectFromTemplate(
                    this.formProject,
                    this.formProject.template
                )
                    .then(() => this.hideLoading())
                    .then(() => this.$bvModal.hide('new-project-modal'))
                    .catch((e) => {
                        this.hideLoading()
                        this.formProject.nameState = false
                        this.formProject.feedback = e.response.data.detail
                    })
            } else {
                this.$store
                    .dispatch('newProject', this.formProject)
                    .then(() => this.hideLoading())
                    .then(() => this.$bvModal.hide('new-project-modal'))
                    .catch((e) => {
                        this.hideLoading()
                        this.formProject.nameState = false
                        this.formProject.feedback = e.response.data.detail
                    })
            }
        },
        renameProjectModal(project) {
            this.formProject = Object.assign({}, project)
            this.$bvModal.show('rename-project-modal')
        },
        renameProject(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.showLoading()
            this.$store
                .dispatch('editProject', this.formProject, {})
                .then(() => this.hideLoading())
                .then(() => this.$bvModal.hide('rename-project-modal'))
                .catch((e) => {
                    this.hideLoading()
                    this.formProject.nameState = false
                    this.formProject.feedback = e.response.data.detail
                })
        },
        deleteProject(project) {
            this.$bvModal
                .msgBoxConfirm(
                    'Are you sure you want to delete "' +
                        project.name +
                        '" and all its associated files, builds & artifacts.',
                    {
                        title: 'Delete Project',
                        size: 'md',
                        buttonSize: 'md',
                        okVariant: 'danger',
                        okTitle: 'DELETE',
                        cancelTitle: 'Cancel',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true,
                    }
                )
                .then((value) => {
                    this.showLoading()
                    if (value)
                        this.$store
                            .dispatch('deleteProject', project)
                            .then(() => this.hideLoading())
                    else this.hideLoading()
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        fetchBuilds() {
            this.projects.map((p) =>
                APIInstance()
                    .getLatestBuild(p.project.id)
                    .then((r) => Vue.set(p, 'latest_build', r.data))
            )
        },
    },
}
</script>

<style lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.project-cell {
    display: flex;
    align-items: center;
}
.project-icon {
    height: 2rem;
    width: 2rem;
    background-color: var(--secondary);
    border-radius: 50%;
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
}
.project-icon.build {
    height: 1.5rem;
    width: 1.5rem;
    background-color: $gray-200;
    color: var(--black);
}
.project-icon.build svg {
    height: 1.5rem;
    width: 1.5rem;
    fill: var(--secondary);
}
</style>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.empty-table {
    margin: 10rem auto;
}
.empty-table svg {
    height: 6rem;
    width: 6rem;
}
.empty-table__icon {
    height: 8rem;
    width: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 50%;
    background-color: $gray-200;
    margin: 0 auto;
}
.empty-table__content {
    text-align: center;
    line-height: 1.75rem;
}
</style>
