<template>
    <b-tab>
        <template #title>
            <ph-key size="24" />
            <span class="ml-3">API Keys</span>
        </template>
        <div class="mt-3">
            <b-row>
                <b-col class="d-flex align-items-center">
                    <h2 class="font-weight-bold mb-0">API Keys</h2>
                </b-col>
                <b-col class="mt-3 mt-lg-0 d-flex" cols="3">
                    <b-button
                        class="ml-3"
                        block
                        variant="primary"
                        v-b-modal.new-key-modal
                    >
                        Add new key
                    </b-button>
                </b-col>
            </b-row>
            <b-table
                id="keys-table"
                class="mt-6"
                :fields="columns"
                :items="userKeys"
                :per-page="perPage"
                :current-page="currentPage"
                responsive
            >
                <template #cell(name)="data">
                    <span>{{ data.value }}</span>
                </template>

                <template #cell(scopes)="data">
                    <span>{{ data.value.join(', ') }}</span>
                </template>

                <template #cell(created_at)="data">
                    <span>
                        {{ formatDate(data.value) }}
                    </span>
                </template>

                <template #cell(actions)="data">
                    <div class="api-key-cell">
                        <ph-trash-simple
                            size="20"
                            role="button"
                            v-b-tooltip.hover.bottom="'Revoke'"
                            @click="revokeKey(data.item)"
                        ></ph-trash-simple>
                    </div>
                </template>
            </b-table>
            <div v-if="userKeys.length === 0" class="empty-table">
                <div class="empty-table__icon">
                    <ph-key />
                </div>
                <p class="empty-table__content mt-4 mb-0 text-muted">
                    Looks like there are no API keys created yet.<br />Would you
                    like to
                    <a href="javascript:void(0)" v-b-modal.new-key-modal
                        >create</a
                    >
                    one?
                </p>
            </div>
            <b-modal
                centered
                id="new-key-modal"
                title="Add API Key"
                @show="resetformCreateKey"
                @ok="createNewKey"
                :cancel-title="formSuccessKeyAdded == null ? 'Cancel' : 'Close'"
                :ok-disabled="formSuccessKeyAdded != null"
            >
                <b-form @submit="createNewKey">
                    <b-form-group
                        label="Name"
                        label-for="keyFormName"
                        description="A distinct and clear name (letters, numbers, hyphens, or underscores) for easy reference of the API key"
                    >
                        <b-form-input
                            v-model="keyFormName"
                            :autofocus="true"
                            id="name"
                            :readonly="formSuccessKeyAdded != null"
                            required
                        />
                    </b-form-group>
                    <b-form-group label="Select scopes to include:">
                        <b-form-checkbox-group
                            v-model="keyFormScopes"
                            :disabled="formSuccessKeyAdded != null"
                            :options="availableScopes"
                        />
                    </b-form-group>
                </b-form>
                <div v-if="formFeedback" class="d-block invalid-feedback">
                    {{ formFeedback }}
                </div>
                <div v-if="formSuccessKeyAdded">
                    <b-alert show variant="success">
                        <span class="text-dark font-weight-bolder"
                            >Your new API key!</span
                        >
                        <b-input-group class="mt-3" size="md">
                            <b-form-input
                                readonly
                                :value="formSuccessKeyAdded"
                                ref="newAPIKey"
                                v-on:focus="$event.target.select()"
                            >
                            </b-form-input>
                            <b-input-group-append>
                                <b-input-group-text>
                                    <ph-clipboard
                                        size="20"
                                        role="button"
                                        @click="copyToClipboard"
                                    />
                                </b-input-group-text>
                            </b-input-group-append>
                        </b-input-group>
                        <p class="text-muted mt-1">
                            Make sure you save it - you won't be able to access
                            it again.
                        </p>
                    </b-alert>
                </div>
                <template #modal-ok>Create</template>
            </b-modal>
        </div>
    </b-tab>
</template>

<script>
import { PhKey, PhTrashSimple, PhClipboard } from 'phosphor-vue'
import { APIInstance } from '@/api'
import createToast from '../mixins/toast'
import { formatDate, formatToRelativeTime } from '../../util/util'

export default {
    name: 'APIKeys',
    components: {
        PhKey,
        PhTrashSimple,
        PhClipboard,
    },
    mixins: [createToast],
    mounted() {
        this.fetchKeys()
    },
    data() {
        return {
            userKeys: [],
            columns: [
                { key: 'name' },
                { key: 'scopes' },
                { key: 'created_at' },
                { key: 'actions' },
            ],
            currentPage: 1,
            perPage: 10,
            keyScopes: null,
            keyFormName: '',
            keyFormScopes: [],
            formFeedback: null,
            formSuccessKeyAdded: null,
        }
    },
    computed: {
        availableScopes() {
            if (!this.keyScopes) this.fetchAvailableScopes()
            return this.keyScopes
        },
    },
    methods: {
        formatDate: formatDate,
        formatToRelativeTime: formatToRelativeTime,
        resetformCreateKey() {
            this.keyFormName = ''
            this.keyFormScopes = []
            this.formFeedback = null
            this.formSuccessKeyAdded = null
        },
        isKeyNameValid() {
            var re = /^[\w\-_\s]+$/
            return this.keyFormName.length > 0 && re.test(this.keyFormName)
        },
        isKeyScopesValid() {
            return this.keyFormScopes && this.keyFormScopes.length > 0
        },
        createNewKey(bvModalEvt) {
            bvModalEvt.preventDefault()
            if (!this.isKeyNameValid()) {
                this.formFeedback =
                    'Name is required and can only contain letters, numbers, hyphens, or underscores'
                return
            }

            if (!this.isKeyScopesValid()) {
                this.formFeedback = 'Please select at least one scope'
                return
            }
            this.formFeedback = null
            APIInstance()
                .generateAPIKey(null, {
                    name: this.keyFormName,
                    scopes: this.keyFormScopes,
                })
                .then((r) => {
                    this.formSuccessKeyAdded = r.data
                    this.fetchKeys()
                })
                .catch((e) => {
                    this.formFeedback = e.response.data.detail
                })
        },
        revokeKey(key) {
            this.$bvModal
                .msgBoxConfirm(
                    'Are you sure you want to revoke "' + key.name + '"',
                    {
                        title: 'Revoke API key',
                        size: 'md',
                        buttonSize: 'md',
                        okVariant: 'danger',
                        okTitle: 'REVOKE',
                        cancelTitle: 'Cancel',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true,
                    }
                )
                .then((value) => {
                    if (value) {
                        APIInstance()
                            .revokeUserAPIKey(key.id)
                            .then(() => this.fetchKeys())
                            .catch(() => {
                                this.createToast(
                                    'Cannot revoke key, error occurred',
                                    'danger'
                                )
                            })
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        fetchKeys() {
            APIInstance()
                .getAPIKeysForUser()
                .then((r) => {
                    this.userKeys = r.data
                })
        },
        fetchAvailableScopes() {
            APIInstance()
                .getScopesForAPIKeys()
                .then((r) => {
                    this.keyScopes = r.data
                })
        },
        copyToClipboard() {
            this.$refs.newAPIKey.focus()
            document.execCommand('copy')
        },
    },
}
</script>

<style lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.api-key-cell {
    display: flex;
    align-items: center;
}

.empty-table {
    margin: 10rem auto;
}

.empty-table svg {
    height: 6rem;
    width: 6rem;
}

.empty-table__icon {
    height: 8rem;
    width: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 50%;
    background-color: $gray-200;
    margin: 0 auto;
}
.empty-table__content {
    text-align: center;
    line-height: 1.75rem;
}
</style>
