import axios from 'axios'
import { getInstance } from '@/auth' //TODO use APIInstance

export default {
    methods: {
        async downloadFile(url, name) {
            const token = await getInstance().getTokenSilently()
            axios({
                url: url,
                method: 'GET',
                headers: { Authorization: `Bearer ${token}` },
                responseType: 'blob',
            }).then((response) => {
                var fileURL = window.URL.createObjectURL(
                    new Blob([response.data])
                )
                var fileLink = document.createElement('a')
                fileLink.href = fileURL
                fileLink.setAttribute('download', name)
                document.body.appendChild(fileLink)

                fileLink.click()
            })
        },
    },
}
