<template>
    <div class="d-flex flex-column">
        <span>Hardware version</span>
        <b-form-select
            v-model="formProject.hw_ver"
            :state="formProject.hw_ver != null"
            default="Hardware version"
            :options="hardwareVersions()"
            class="mb-2"
            @change="handleHWChange"
        >
            <template #first>
                <b-form-select-option :value="null" disabled
                    >Select Hardware</b-form-select-option
                >
            </template>
        </b-form-select>
        <span>No# of slots</span>
        <b-form-select
            v-model="formProject.slots"
            :state="formProject.slots != null"
            default="No# of slots"
            value-field="id"
            text-field="name"
            :options="slotsForHardwareVersion()"
            class="mb-2"
            :disabled="formProject.hw_ver == null"
            @change="handleSlotsChange"
        >
            <template #first>
                <b-form-select-option :value="null" disabled
                    >Select Slots</b-form-select-option
                >
            </template>
        </b-form-select>
        <span>Firmware version</span>
        <b-form-select
            v-model="formProject.fw_ver"
            :state="formProject.fw_ver != null"
            default="Firmware version"
            value-field="id"
            text-field="name"
            :options="availableBuildProps.fw_ver"
            class="mb-2"
            @change="handleFWChange"
        >
            <template #first>
                <b-form-select-option :value="null" disabled
                    >Select Firmware</b-form-select-option
                >
            </template>
        </b-form-select>
        <div v-if="!arePropertiesValid()" class="d-block invalid-feedback">
            {{ propsFeedback }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProjectConfiguration',
    props: {
        projectDetails: Object,
    },
    data() {
        return {
            formProject: {
                fw_ver: null,
                hw_ver: null,
                slots: null,
            },
            formProjectDirty: false,
            propsFeedback:
                'Hardware version, Firmware version and slots are required',
        }
    },
    watch: {
        projectDetails: function () {
            this.populateCurrentConfiguration()
        },
    },
    beforeDestroy() {
        this.initialForm()
    },
    mounted() {
        this.$store.dispatch('availableConfiguration').then(() => {
            this.populateCurrentConfiguration()
        })
    },
    computed: {
        availableBuildProps() {
            return this.$store.state.availableConfig
        },
    },
    methods: {
        initialForm() {
            return {
                fw_ver: null,
                hw_ver: null,
                slots: null,
            }
        },
        hardwareVersions() {
            if (Object.keys(this.availableBuildProps).length) {
                return Object.fromEntries(
                    Object.entries(this.availableBuildProps.hw_ver).map(
                        ([key, [val]]) => [key, val]
                    )
                )
            }
        },
        slotsForHardwareVersion() {
            if (this.availableBuildProps.hw_ver && this.formProject.hw_ver)
                return this.availableBuildProps.hw_ver[
                    this.formProject.hw_ver
                ][1]
            else return []
        },
        arePropertiesValid() {
            return (
                this.formProject.hw_ver &&
                this.formProject.fw_ver &&
                this.formProject.slots
            )
        },
        handleFWChange(value) {
            this.handleFormChange('fw_ver', value)
        },
        handleHWChange(value) {
            this.handleFormChange('hw_ver', value)
        },
        handleSlotsChange(value) {
            this.handleFormChange('slots', value)
        },
        handleFormChange(name, value) {
            this.formProjectDirty = true
            let propertiesForm = {}
            propertiesForm['id'] = this.projectDetails.id
            propertiesForm['properties'] = {}
            propertiesForm['properties'][name] = value
            this.$store.dispatch('editProject', propertiesForm).catch((e) => {
                this.propsFeedback = e.response.data.detail
            })
        },
        populateCurrentConfiguration() {
            this.formProject = this.initialForm()
            if (
                this.projectDetails != null &&
                this.projectDetails.properties != null
            ) {
                const properties = this.projectDetails.properties
                this.formProject.hw_ver = properties['hw_ver']
                this.formProject.slots = properties['slots']
                this.formProject.fw_ver = properties['fw_ver']
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.was-validated .custom-select:valid,
.custom-select.is-valid {
    border-color: #ced4da;
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
    border-color: #ced4da;
    box-shadow: none;
}
</style>
