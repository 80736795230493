<template>
    <b-modal
        centered
        :id="id"
        title="Upload file(s)"
        :ok-disabled="uploadFiles.length == 0"
        @ok="uploadFilesAsync"
        @show="resetFormUploadFiles"
    >
        <b-form-file
            multiple
            accept=".vhd, .xci, .coe"
            v-model="formUploadFiles"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            browse-text="Browse files"
            :file-name-formatter="formatNames"
        ></b-form-file>
        <small class="form-text text-muted ml-2"
            >Choose VHD, XCI or COE files to upload</small
        >

        <b-table
            id="file-uploader-table"
            v-if="uploadFiles.length > 0"
            class="mt-3"
            :fields="columns"
            :items="uploadFiles"
            sticky-header
            responsive="md"
            tbody-tr-class="mx-auto"
        >
            <template #cell(name)="data">
                <span>{{ data.value }}</span>
            </template>
            <template #cell(timestamp)="data">
                <span>{{ formatUnixDate(data.value) }}</span>
            </template>
            <template #cell(status)="data">
                <b-button
                    v-if="data.value == 'pending'"
                    variant="link"
                    class="text-primary p-0"
                    @click="removeFile(data.name)"
                >
                    remove
                </b-button>
                <span v-else-if="data.value == 'success'" class="text-success">
                    <ph-check-circle size="14" />
                    Uploaded
                </span>
                <span v-else class="text-danger">
                    <ph-x-circle size="14" />
                    {{ data.value }}
                </span>
            </template>
        </b-table>
        <template #modal-ok>Upload</template>
    </b-modal>
</template>

<script>
import Vue from 'vue'
import { PhXCircle, PhCheckCircle } from 'phosphor-vue'
import { formatUnixDate } from '../../../util/util'
export default {
    name: 'FileUploader',
    props: ['id'],
    data() {
        return {
            formUploadFiles: [],
            columns: [
                { key: 'name' },
                { key: 'timestamp', label: 'Last Updated' },
                { key: 'status', label: '' },
            ],
        }
    },
    components: {
        PhCheckCircle,
        PhXCircle,
    },
    computed: {
        uploadFiles() {
            return this.formUploadFiles.map((f) => {
                return {
                    name: f.name,
                    timestamp: f.lastModified,
                    status: 'pending',
                }
            })
        },
    },
    methods: {
        formatUnixDate: formatUnixDate,
        resetFormUploadFiles() {
            this.formUploadFiles = []
        },
        removeFile(index) {
            this.formUploadFiles.splice(index, 1)
        },
        formatNames() {
            return `${this.formUploadFiles.length} files selected`
        },
        async readUploadedFile(f) {
            const reader = new FileReader()
            return new Promise((resolve, reject) => {
                reader.onload = (event) => resolve(event.target.result)
                reader.onerror = (error) => reject(error)
                reader.readAsText(f)
            })
        },
        async uploadFilesAsync(bvModalEvt) {
            bvModalEvt.preventDefault()
            const project = this.$store.state.openProject
            this.formUploadFiles.forEach(async (f) => {
                let name = f.name
                let content = await this.readUploadedFile(f)
                this.$store
                    .dispatch('uploadFile', { name, project, content })
                    .then(() => {
                        Vue.set(
                            this.uploadFiles.find((f) => f.name == name),
                            'status',
                            'success'
                        )
                    })
                    .catch((e) => {
                        Vue.set(
                            this.uploadFiles.find((f) => f.name == name),
                            'status',
                            e.response.data.detail
                        )
                        return
                    })
            })
        },
    },
}
</script>
