/**
 * Convert a number lying in one range to a specified range
 * maintaining the ratio.
 * For eg: converting 15 of range 1-20 to range 1-100.
 *
 * @param {number} oldValue
 * @param {{ oldMax: number, oldMin: number, newMax: number, newMin: number }} param1
 * @returns
 */
export const clampToRange = (oldValue, { oldMax, oldMin, newMax, newMin }) => {
    let newValue = 0

    const oldRange = oldMax - oldMin
    if (oldRange == 0) {
        newValue = newMin
    } else {
        const newRange = newMax - newMin
        newValue = ((oldValue - oldMin) * newRange) / oldRange + newMin
    }

    return newValue
}

/**
 *
 * @param {number} centerX
 * @param {number} centerY
 * @param {number} radius
 * @param {number} angleInDegrees
 * @returns
 */
export const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0

    return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians),
    }
}

/**
 *
 * @param {number} x
 * @param {number} y
 * @param {number} radius
 * @param {number} startAngle
 * @param {number} endAngle
 * @returns {number}
 */
export const describeArc = (x, y, radius, startAngle, endAngle) => {
    const start = polarToCartesian(x, y, radius, endAngle)
    const end = polarToCartesian(x, y, radius, startAngle)

    const arcSweep = endAngle - startAngle <= 180 ? '0' : '1'

    const d = [
        'M',
        start.x,
        start.y,
        'A',
        radius,
        radius,
        0,
        arcSweep,
        0,
        end.x,
        end.y,
        'L',
        x,
        y,
        'L',
        start.x,
        start.y,
    ].join(' ')

    return d
}

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(relativeTime)
dayjs.extend(timezone)
dayjs.extend(utc)

/**
 *
 * @param {number} date
 * @returns {string}
 */
export const formatDate = (date) => {
    const tzOffset = new Date().getTimezoneOffset()
    const dt = dayjs(date).utcOffset(tzOffset, true)
    return dt.format('D/MMM/YYYY')
}

/**
 *
 * @param {number} date
 * @returns {string}
 */
export const formatDateTime = (date) => {
    const tzOffset = new Date().getTimezoneOffset()
    const dt = dayjs(date).utcOffset(tzOffset, true)
    return dt.format('D/MMM/YYYY HH:mm:ss')
}

/**
 *
 * @param {number} date
 * @returns {string}
 */
export const formatUnixDate = (epoch) => {
    const tzOffset = new Date().getTimezoneOffset()
    const dt = dayjs.unix(epoch).utcOffset(tzOffset, true)
    return dt.format('D/MMM/YYYY HH:mm:ss')
}

/**
 *
 * @param {number} date
 * @returns {string}
 */
export const formatToRelativeTime = (date) => {
    const tzOffset = new Date().getTimezoneOffset()
    const withOffset = dayjs.utc(date).utcOffset(tzOffset, true)
    const currentTime = dayjs().utcOffset(tzOffset, true)
    return dayjs(currentTime).to(withOffset)
}

/**
 *
 * @param {number} from
 * @param {number} to
 * @returns {string}
 */
export const formatDateDiff = (from, to) => {
    var diff = dayjs.unix(to).diff(dayjs.unix(from), 'seconds')

    var mins = Math.floor(diff / 60)
    var secs = diff - mins * 60
    return mins + ' minutes ' + secs + ' seconds'
}

/**
 *
 * @param {Object} build
 * @param {Object} project
 * @param {string} name
 * @returns {string}
 */
export const composeBitstreamName = (build, project) => {
    let nameParts = []
    // Add Project Name
    nameParts.push(project.slice(0, 6).trim())
    // Add Device details
    if (build && build.hw_ver) nameParts.push(build.hw_ver)
    if (build && build.fw_ver) nameParts.push(build.fw_ver)
    // Add Build ID
    nameParts.push(build.id.slice(-7))

    return nameParts.join('_') + '.tar.gz'
}

/**
 *
 * @param {string} name
 * @param {string} template
 */
import store from '../src/store'
import axios from 'axios'
export const createProjectFromTemplate = async (form, template) => {
    const project = await store.dispatch('newProject', form)
    var projectTemplate = store.state.projectTemplates.find(
        (x) => x['path'] == template
    )
    const templateFiles = projectTemplate['files']
    Promise.all(
        templateFiles.map(function (name) {
            store
                .dispatch('newFile', {name, project})
                .then((file) => {
                    axios
                        .get('/examples/' + template + '/' + name)
                        .then((fr) => {
                            file.content = fr.data
                            const project_id = project.id;
                            store.dispatch('updateFile', {project_id, file})
                        })
                })
        })
    )
}
