var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Main',{scopedSlots:_vm._u([{key:"nav-left",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center h-100 pl-2 p-1"},[_c('span',{staticClass:"color-white"},[_vm._v(" Projects /  "+_vm._s(_vm.projectName)+" ")])])]},proxy:true}])},[_c('b-overlay',{staticClass:"h-100",attrs:{"show":_vm.loading}},[(_vm.builds.length)?_c('b-tabs',{staticClass:"w-100 h-100",attrs:{"pills":"","vertical":"","nav-class":"split-layout-vertical-tabs border-right","no-fade":"","lazy":""},on:{"input":_vm.selectedBuild}},_vm._l((_vm.builds),function(b){return _c('b-tab',{key:b.id,attrs:{"button-id":b.id},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('build-cell',{attrs:{"projectName":_vm.projectName,"build":b}})]},proxy:true}],null,true)},[_c('div',{staticClass:"p-6"},[_c('b-row',{staticClass:"flex-1 pb-6 border-bottom"},[_c('b-col',{attrs:{"lg":"8"}},[_c('h2',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Build")]),_c('strong',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.activeBuildDetail.id.slice(-7))+" ")])])])],1),_c('b-row',{staticClass:"flex-1 mt-6",staticStyle:{"row-gap":"2rem"},attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"lg":"2"}},[_c('h4',{staticClass:"font-weight-bold mb-0"},[_vm._v("Details")])]),_c('b-col',{attrs:{"sm":"6","lg":"3"}},[_c('p',{staticClass:"spaced-text"},[_vm._v("STATUS")]),_c('div',{staticClass:"d-flex"},[_c('build-status-icon',{attrs:{"build":_vm.activeBuildDetail,"detail":true}})],1)]),_c('b-col',{attrs:{"sm":"6","lg":"3"}},[_c('p',{staticClass:"spaced-text"},[_vm._v("QUEUED")]),(_vm.activeBuildDetail.timestamp)?_c('span',[_vm._v(_vm._s(_vm.formatDateTime( _vm.activeBuildDetail.timestamp ))+" ")]):_c('span',[_vm._v("-")])]),_c('b-col',{attrs:{"sm":"6","lg":"3"}},[_c('p',{staticClass:"spaced-text"},[_vm._v("DURATION")]),(
                                    _vm.activeBuildDetail.started_at &&
                                    _vm.activeBuildDetail.completed_at
                                )?_c('span',[_vm._v(_vm._s(_vm.formatDateDiff( _vm.activeBuildDetail.started_at, _vm.activeBuildDetail.completed_at ))+" ")]):_c('span',[_vm._v("-")])])],1),_c('b-row',{staticClass:"flex-1 mt-6 pb-6 border-bottom",staticStyle:{"row-gap":"2rem"},attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"lg":"2"}}),_c('b-col',{attrs:{"sm":"6","lg":"3"}},[_c('p',{staticClass:"spaced-text"},[_vm._v("HARDWARE VERSION")]),_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v(_vm._s(_vm.activeBuildDetail.hw_ver || '-')+" ")])])]),_c('b-col',{attrs:{"sm":"6","lg":"3"}},[_c('p',{staticClass:"spaced-text"},[_vm._v("FIRMWARE VERSION")]),_c('span',[_vm._v(_vm._s(_vm.activeBuildDetail.fw_ver || '-')+" ")])]),_c('b-col',{attrs:{"sm":"6","lg":"3"}},[_c('p',{staticClass:"spaced-text"},[_vm._v("NO. OF SLOTS")]),_c('span',[_vm._v(_vm._s(_vm.activeBuildDetail.slots || '-')+" ")])])],1),_c('b-row',{staticClass:"flex-1 mt-6 pb-6 border-bottom",staticStyle:{"row-gap":"2rem"},attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"lg":"2"}},[_c('h4',{staticClass:"font-weight-bold mb-0"},[_vm._v("Artifacts")])]),_c('b-col',{staticClass:"reports-table",attrs:{"lg":"10"}},[_c('b-table',{attrs:{"items":_vm.activeBuildDetail.artifacts,"responsive":"","thead-class":"d-none"},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('b-link',{on:{"click":function($event){return _vm.downloadFile(
                                                '/api/builds/' +
                                                    _vm.activeBuild +
                                                    '/artifacts/' +
                                                    data.item.id,
                                                data.item.name
                                            )}}},[_c('ph-download-simple'),_c('span',{staticClass:"p-2"},[_vm._v("Download")])],1)]}},{key:"cell(id)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" ")]}}],null,true)})],1)],1),_c('b-row',{staticClass:"flex-1 mt-6 pb-6 border-bottom",staticStyle:{"row-gap":"2rem"},attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"lg":"2"}},[_c('h4',{staticClass:"font-weight-bold mb-0"},[_vm._v("Logs")])]),_c('b-col',{staticClass:"reports-table",attrs:{"lg":"10"}},[_c('BuildProgress',{attrs:{"buildId":_vm.activeBuild},on:{"buildStatusChanged":_vm.loadBuildData}})],1)],1)],1)])}),1):_c('div',{staticClass:"empty-table"},[_c('div',{staticClass:"empty-table__icon"},[_c('ph-wrench')],1),_c('p',{staticClass:"empty-table__content mt-4 mb-0 text-muted"},[_vm._v(" Hi there, it seems like you don’t have any builds for "),_c('strong',[_vm._v(_vm._s(_vm.projectName))]),_vm._v(" project yet. ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }