<template>
    <div>
        <div class="border-bottom" v-if="buildId">
            <b-row align-h="end">
                <b-col cols="3" class="my-auto">
                    <b-form-checkbox v-model="autoScroll" size="md" switch
                        >Auto Scroll
                    </b-form-checkbox>
                </b-col>
            </b-row>
            <div ref="editorLog" class="editor-logs flex h-100"></div>
        </div>
        <div v-else class="row">
            <span class="mx-auto mb-2 text-muted">No build history</span>
        </div>
    </div>
</template>

<script>
import { APIInstance } from '@/api'
import { EditorState } from '@codemirror/basic-setup'
import { EditorView } from '@codemirror/view'
import { lineNumbers } from '@codemirror/gutter'
import { displayStripes } from '../../util/logDecorator'

export default {
    name: 'BuildLog',
    props: {
        buildId: null,
        stepId: null,
    },
    data: function () {
        return {
            cm: null,
            autoScroll: true,
            numLines: 0,
            poller: null,
        }
    },
    methods: {
        getDefaultEditorState() {
            return EditorState.create({
                doc: '',
                extensions: [
                    lineNumbers(),
                    displayStripes(),
                    EditorView.lineWrapping,
                    EditorView.editable.of(false),
                ],
            })
        },
        fetchLog() {
            if (!this.stepId) return
            const stepId = this.stepId
            const offset = this.numLines

            APIInstance()
                .getStepLog({
                    build_id: this.buildId,
                    step_id: this.stepId,
                    offset: this.numLines,
                    limit: 500,
                })
                .then((d) => {
                    // Discard responses that are not relevant anymore, like
                    // duplicate logs or logs from a different step
                    if (this.stepId !== stepId || this.numLines !== offset) {
                        return
                    }

                    let data = d.data
                    let content = data.log
                    if (content && content.length) {
                        this.cm.dispatch({
                            changes: {
                                from: this.cm.state.doc.length,
                                // append new line at the end, so
                                // that next set of results start
                                // from new line
                                insert: content.join('\n') + '\n',
                            },
                        })
                        this.numLines = this.numLines + content.length

                        if (this.autoScroll) {
                            let range = this.cm.state.doc.line(
                                this.cm.state.doc.lines
                            )
                            this.cm.scrollPosIntoView(range.to)
                        }
                    }
                    if (this.numLines >= data.log_length && data.complete) {
                        clearInterval(this.poller)
                    }
                })
        },
        beginPolling() {
            this.cm.setState(this.getDefaultEditorState())
            this.numLines = 0
            this.fetchLog()
            if (this.poller) clearInterval(this.poller)
            this.poller = setInterval(this.fetchLog, 1500)
        },
        stepsFinished() {
            this.$emit('stepsFinished')
        },
    },
    watch: {
        // TODO debounce
        buildId() {
            this.beginPolling()
        },
        stepId() {
            this.beginPolling()
        },
    },
    mounted: function () {
        this.cm = new EditorView({
            state: this.getDefaultEditorState(),
            parent: this.$refs.editorLog,
        })
        this.beginPolling()
    },
    beforeDestroy: function () {
        clearInterval(this.poller)
    },
}
</script>
