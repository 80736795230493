<template>
    <b-col class="menu-container border-right" id="sidebar">
        <b-col class="menu py-4">
            <b-button
                variant="transparent"
                to="/projects"
                v-b-tooltip.hover.right="'Projects'"
            >
                <ph-folder :size="24" />
            </b-button>
            <b-button
                variant="transparent"
                :to="/e/ + projectId"
                v-if="projectOpen"
                v-b-tooltip.hover.right="'Editor'"
            >
                <ph-code :size="24" />
            </b-button>
            <b-button
                variant="transparent"
                :to="/builds/ + projectId"
                v-if="projectOpen"
                v-b-tooltip.hover.right="'Builds'"
            >
                <ph-wrench size="24" />
            </b-button>
        </b-col>

        <b-col class="menu py-4 justify-content-end">
            <b-button
                variant="transparent"
                v-b-tooltip.hover.right="'Documentation'"
                href="https://apis.liquidinstruments.com/mcc/"
                target="_blank"
            >
                <ph-question :size="24" />
            </b-button>
            <b-button
                variant="transparent"
                v-b-tooltip.hover.right="'Preferences'"
                to="/preferences"
            >
                <ph-user-circle-gear :size="24" />
            </b-button>
        </b-col>
    </b-col>
</template>

<script>
import {
    PhFolder,
    PhCode,
    PhWrench,
    PhQuestion,
    PhUserCircleGear,
} from 'phosphor-vue'
export default {
    name: 'SideBar',
    components: {
        PhWrench,
        PhFolder,
        PhCode,
        PhQuestion,
        PhUserCircleGear,
    },
    computed: {
        projectOpen() {
            return Boolean(this.$store.state.openProject)
        },
        projectId() {
            let p = this.$store.state.openProject
            if (p) return p.id
            return ''
        },
    },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.menu-container {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 5rem;
    background-color: var(--light);
    flex-direction: column;
    margin: 0;
}

.menu {
    text-align: center;
    cursor: pointer;
    padding: 0;
    list-style: none;
    display: flex;
    row-gap: 2rem;
    flex-direction: column;

    .btn {
        padding: 0;
        height: 3rem;
        width: 3rem;
        transform: scale(1);
        transition: transform 0.2s ease, background-color 0.2s ease;
        border-radius: 50%;
        color: var(--secondary);

        display: flex;
        justify-content: center;
        align-items: center;

        &:hover,
        &.router-link-active {
            background-color: $gray-200;
            transform: scale(1.25);
            color: #000 !important;
        }
    }
}
</style>
